import React from "react"

const Qna = () => {
  return (
    <div>
      <p>&nbsp;</p>
      <ol>
        <li>
          如何建立帳戶? <br />
          <br />
          Ans:
          您可以使用任何電郵地址註冊，或使用Google/Facebook帳戶直接登入。若使用電郵地址註冊新帳戶，我們會向該電郵地址發送認證訊息，待認證成功後，再進入下一步。無論以任何方式註冊帳戶，都必須輸入付款資訊，選購合適服務計劃，整個程序才算完成。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          如何重設我的密碼?
          <br />
          <br />
          Ans:
          用戶可在登入後，前往「用戶中心」&gt;「帳戶資料」&gt;「更改密碼」進行修改。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          忘記密碼，如何是好
          <br />
          <br />
          Ans:
          用戶可在登入介面點撃「忘記密碼」，系統會向你已註冊的電郵地址發送更改密碼訊息，再完成相關步驟便可。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="4">
        <li>
          如何更改付款資料?
          <br />
          <br />
          Ans:
          用戶可在登入後，前往「用戶中心」&gt;「帳戶資料」&gt;「修改訂閱資料」進行修改。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          如何重溫已完成練習?
          <br />
          <br />
          Ans:
          用戶可在登入後，前往「用戶中心」&gt;「過往練習」，再選擇想要重溫的練習。
        </li>
      </ol>
      <p>
        &nbsp;
        <br />
        &nbsp;
      </p>
      <ol start="6">
        <li>
          如何查閱已收藏題目? <br />
          <br />
          Ans:
          用戶可在登入後，前往「用戶中心」&gt;「收藏題目」，再選擇想要查閱的特定題目。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="7">
        <li>
          如何取消訂閱?
          <br />
          <br />
          Ans:
          用戶可在登入後，前往「用戶中心」&gt;「帳戶資料」&gt;「修改訂閱資料」然後處理。
        </li>
      </ol>
    </div>
  )
}

export default Qna
